import React from 'react'
import Img from 'gatsby-image'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import HTMLContent from '../HTMLContent'

function buildColumnClasses(cols) {
  const [m, t, d] = cols
  return [
    `col-${m}_tp-${t}_ds-${d}`,
    `col-${m === 12 ? 12 : 12 - m}_tp-${t === 12 ? 12 : 12 - t}_ds-${
      d === 12 ? 12 : 12 - d
    }`,
  ]
}

const Row = ({
  alignment,
  imgColClass,
  txtColClass,
  alternate,
  i,
  img,
  icon,
  txt,
  inlineImages,
}) => {
  const imgCol = (
    <div
      key={uuidv4()}
      className={`row-img_holder ${imgColClass} ${!!icon ? 'icon-img' : ''}`}
    >
      {!!icon && !!icon.src && !!icon.src.publicURL && (
        <img src={icon.src.publicURL} alt={icon.alt} />
      )}
      {!!img && !!img.src && !!img.src.childImageSharp && (
        <Img fluid={img.src.childImageSharp.fluid} alt={img.alt} />
      )}
    </div>
  )
  const txtCol = (
    <HTMLContent
      key={uuidv4()}
      className={`row-image ${txtColClass}`}
      inlineImages={inlineImages}
      content={txt}
    />
  )
  const main = alignment === 'left' ? [imgCol, txtCol] : [txtCol, imgCol]
  return (
    <div
      className={`grid-spaceAround${
        alternate && i % 2 !== 0 ? '-reverse' : ''
      }`}
    >
      {main}
    </div>
  )
}

Row.propTypes = {
  alignment: PropTypes.string.isRequired,
  imgColClass: PropTypes.string.isRequired,
  txtColClass: PropTypes.string.isRequired,
  alternate: PropTypes.bool.isRequired,
  i: PropTypes.number.isRequired,
  img: PropTypes.shape({
    src: PropTypes.object.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  icon: PropTypes.shape({
    src: PropTypes.object.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  txt: PropTypes.string.isRequired,
  inlineImages: PropTypes.array,
}

const ImgTxtRowPanel = ({
  rowMeta: { colWidth, alignment, alternate },
  rows,
  inlineImages,
}) => {
  const [imgColClass, txtColClass] = buildColumnClasses(colWidth)

  return !!rows && rows.length
    ? rows.map(({ img, icon, txt_MD }, i) => (
        <Row
          key={uuidv4()}
          alignment={alignment}
          imgColClass={imgColClass}
          txtColClass={txtColClass}
          alternate={alternate}
          i={i}
          img={img}
          icon={icon}
          txt={txt_MD}
          inlineImages={inlineImages}
        />
      ))
    : null
}

ImgTxtRowPanel.propTypes = {
  align: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
  subtitle_MD: PropTypes.string,
  rowMeta: PropTypes.shape({
    colWidth: PropTypes.array.isRequired,
    alignment: PropTypes.string.isRequired,
    alternate: PropTypes.bool.isRequired,
  }).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.shape({
        src: PropTypes.object.isRequired,
        alt: PropTypes.string.isRequired,
      }),
      icon: PropTypes.shape({
        src: PropTypes.object.isRequired,
        alt: PropTypes.string.isRequired,
      }),
      txt_MD: PropTypes.string.isRequired,
    }),
  ).isRequired,
  inlineImages: PropTypes.array,
}

export default ImgTxtRowPanel
